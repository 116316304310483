import { Icon } from '/features/buildingBlocks/Icon'
import { PublishedDate } from './PublishedDate'
import { useTranslate } from '/machinery/I18n'

import iconClock from '/images/icons/clock.raw.svg'
import iconMoney from '/images/icons/euro.raw.svg'
import styles from './Labels.css'

export function Labels({ labels, layoutClassName = undefined }) {
  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {labels.map(({ title, icon }, i) => (
        <Label key={i} {...{ title, icon }}  />
      ))}
    </ul>
  )
}

export function LabelsWithDate({ labels, layoutClassName = undefined, date }) {
  const { __ } = useTranslate()

  return (
    <ul className={cx(styles.componentWithDate, layoutClassName)}>
      {labels.map(({ title, icon }, i) => (
        <Label key={i} {...{ title, icon }}  />
      ))}
      <li className={styles.date}>

        <span>{__`vacancy-published-date`} <PublishedDate {...{ date }} /></span>
      </li>
    </ul>
  )
}

function Label({ title, icon = undefined }) {
  return (
    <li className={styles.componentLabel}>
      {icon && <Icon icon={getIcon(icon)} layoutClassName={styles.iconLayout} />}
      <span>{title}</span>
    </li>
  )

  function getIcon(iconName) {
    switch (iconName) {
      case 'time':
        return iconClock
      case 'money':
        return iconMoney
      default:
        return null
    }
  }
}
